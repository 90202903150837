/* Pre-defined color variables */

:root {
    --transition: all 0.5s ease;
}


$primary: #4B4EFC;
$clr-copy: #292929;
$clr-red: #f10075;
$clr-green: #00cccc;
$color--text: #546a79;
$color--border: #e1e1e1;
$radius: 2px;
$field-border: #D0D5DD;
$clr-error: #e74c3c;
$white: #fff;


$white: #fff;
$black: #000;
$nuetral-50: #f9fafb;
$nuetral-100: #f3f4f6;
$nuetral-200: #e5e7eb;
$nuetral-300: #d1d5db;
$nuetral-400: #9ca3af;
$nuetral-500: #6b7280; 
$nuetral-600: #4b5563;
$nuetral-700: #374151;
$nuetral-800: #1f2937;
$nuetral-900: #111827;
$shades-00: #ffffff;
$shades-100: #000000;
$primary-50: #f4f4ff;
$primary-100: #e1e1fe;
$primary-200: #c3c4fe;
$primary-300: #9b9dfd;
$primary-400: #4b4efc;
$primary-500: #4b4efc;
$primary-600: #1e22fb;
$primary-700: #0408e7;
$primary-800: #0306ba;
$primary-900: #02058d;
$success-50: #f0fdf4;
$success-100: #dcfce7;
$success-200: #bbf7d0;
$success-300: #86efac;
$success-400: #4ade80;
$success-500: #22c55e;
$success-600: #16a34a;
$success-700: #15803d;
$success-800: #166534;
$success-900: #14532d;
$destructive-50: #fef2f2;
$destructive-100: #fee2e2;
$destructive-200: #fecaca;
$destructive-300: #fca5a5;
$destructive-400: #f87171;
$destructive-500: #ef4444;
$destructive-600: #dc2626;
$destructive-700: #b91c1c;
$destructive-800: #991b1b;
$destructive-900: #7f1d1d;
$warning-50: #fffbeb;
$warning-100: #fef3c7;
$warning-200: #fde68a;
$warning-300: #fcd34d;
$warning-400: #fbbf24;
$warning-500: #f59e0b;
$warning-600: #d97706;
$warning-700: #b45309;
$warning-800: #92400e;
$warning-900: #78350f;
$primary: #4b4efc;


/* Pre-defined fonts variables */

$zindex: (
    header: 32,
    filter-fix: 20,
    sidebar: 31,
    modal: 35,
    modalcontent: 10,
    overlay: 100,
    overlaywidget: 13,
    dropdown: 18,
    bootstrap-dropdown: 19,
    filter: 10
);

$primary-font-bold: "Gilroy-bold", sans-serif;
$primary-font-semibold: "Gilroy-Semibold", sans-serif;
$primary-font-medium: "Gilroy-Medium", sans-serif;
$primary-font-regular: "Gilroy-Regular", sans-serif;
$primary-font-light: "Gilroy-Light", sans-serif;
$secondary-font-regular: "Axiforma-Regular", sans-serif;
$tertiary-font-regular: "Inter", sans-serif;
$icon-font: "Font Awesome 6 Pro";

// Interactions
$transition-time-base: 0.3s; 
$sidebar-width: 260px;

@mixin clearfix() {
    &::after {
        display: block;
        content: '';
        clear: both;
    }
}

