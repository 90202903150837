header {
  width: 100%;
  height: 50px;
  position: fixed;
  display: flex;
  justify-content: space-between;
  align-content: center;
  align-items: center;
  top: 0;
  left: 0;
  padding: 0 20px 0 0;
  border-bottom: 1px solid #E5E7EB;
  z-index: 10;
  background: white;

  h1 {
    font-size: 20px;
    font-weight: 500;
  }
}

.header__left {
  display: flex;
  align-items: center;

  .top__toggle {
    width: 60px;
    padding: 15px 20px;
    border-right: 1px solid #E5E7EB;
  }
}

.logo {
  margin: 0 20px 0 20px;


  .app-logo {
    img {
      max-width: initial;
      vertical-align: middle;
    }
  }
}



.header__actions {
  align-items: center;
  align-content: center;
  display: flex;



  .action__item {

    margin-left: 10px;


    // .action__btn {
    //   display: block;
    //   align-items: center;
    //   align-content: center;
    //   padding: 0 10px;
    //   text-align: center;
    //   font-size: 18px;
    //   color: #9CA3AF;
    //   border-radius: 100%;
    //   width: 35px;
    //   height: 35px;
    //   line-height: 35px;
    //   cursor: pointer;
    //   transition: all 0.3s ease;
    //   position: relative;

    //   &.user{
    //     padding: 0;
    //     width: auto;
    //     height: auto;
    //   }



    //   &:hover {
    //     background: $primary-100;
    //     color: $primary;
    //   }
    // }




  }
}

.user__initial {
  position: relative;
  background: $primary-100;
  font-size: 16px;
  width: 35px;
  height: 35px;
  padding: 0 8px;
  text-align: center;
  color: $primary;
  line-height: 35px;
  border-radius: 100%;
  cursor: pointer;
}

.user__bullet {
  position: absolute;
  bottom: 0;
  right: -5px;
  width: 12px;
  height: 12px;
  border: 2px solid $nuetral-50;
  border-radius: 100%;
  background: $success-500;
}


.apps-component {
  display: flex;
  justify-content: space-between;
  padding: 5px;

  .apps__item {
    text-align: center;
    padding: 0 10px;
    color: #4B5563;

    cursor: pointer;

    i {
      margin-bottom: 3px;
      display: block;
      color: #9CA3AF;
      font-size: 18px;
      transition: all 0.3s ease;
    }



    &.active {
      i {
        color: $primary;
      }
    }

    &:hover {
      i {
        color: $primary;
        font-weight: 600;
      }

    }
  }



}

.profile-module {

  .profile__top {
    border-bottom: 1px solid #F3F4F6;
    position: relative;
    padding: 16px 16px 16px 16px;
    // margin-bottom: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;


    .profile__img {
      position: relative;

      .user__bullet {
        right: 0;
        bottom: 0;
      }

      img {
        border-radius: 100%;
        height: 53px;
        vertical-align: middle;
        width: 53px;
      }
    }

    .profile__detail {
      padding-left: 16px;


      p {
        font-size: 16px;
        font-weight: 600;
        font-family: $primary-font-semibold;

        &.user__email {
          font-size: 13px;
          color: #9CA3AF;
        }
      }


    }




  }

  .profile__list {
    padding: 10px 5px;

    ul {
      li {
        display: block;

        .p-menuitem-link {
          display: block;
          position: relative;
          margin: 0;
          cursor: pointer;
          border-radius: 20px;
          padding: 6px 11px;
          color: #4B5563;
          transition: all 1s ease;

          &:hover {
            // margin-left: 5px;
            background: #f9f9ff;
            color: $primary;
          }
        }
      }
    }
  }
}


.profile__footer {
  padding: 10px 5px;
  border-top: 1px solid #F3F4F6;

  .p-menuitem-link {
    display: block;
    position: relative;
    margin: 0;
    cursor: pointer;
    border-radius: 20px;
    padding: 6px 11px;
    color: #4B5563;
    transition: all 1s ease;

    &:hover {
      // margin-left: 5px;
      background: #f4f4ff;
      color: $primary;
    }
  }
}


.page-header {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-content: center;
  align-items: center;

  h1 {
    font-size: 20px;
    font-weight: 500;
  }
}