.login {
  width: 100%;
  padding: 0;
  background: $white;
  height: 100%;
  background: #F3F4F6;
  background: #F3F4F6 url(../images/bg.jpg) no-repeat;
  background-size: cover;

  @include respondTo($lg) {
    padding: 20px;
  }

}




.form__header {
  margin-bottom: 20px;
  // background: url(../images/logo.svg) no-repeat;
  // max-width: 120px;

}

.login-form {
  height: 100%;
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: center;

  .form {


    width: 275px;



    &__header {
      padding: 0 0 20px;
      text-align: center;

      .logo-login {
        text-align: left;
        display: none;
        margin-bottom: 20px;

        img {
          max-width: 220px;
        }

      }

      h2 {
        font-size: 24px;
        letter-spacing: -1.5px;
        line-height: 1.2;
      }

      p {
        font-size: 16px;
        font-weight: 300;
        color: #667085;
      }
    }

    .form__field {
      position: relative;

      i {
        position: absolute;
        top: 11px;
        color: #6B7280;
        font-size: 14px;
        left: 12px;
      }
    }

    .form__group {
      padding: 0 0 20px;
      position: relative;

      //      @include transition();
      &.login--actions {
        padding-bottom: 0;
      }
    }

    .form__label {
      font-size: 13px;
      font-family: "Gilroy-Medium";
      // text-transform: uppercase;
      font-weight: 300;
      margin-bottom: 5px;
      color: #344054;
      display: block;
    }

    .form__input {
      font-family: "Gilroy-Medium";
      font-weight: 500;
      width: 100%;
      max-width: 100%;
      font-size: 14px;
      color: #666;
      padding: 9px 14px 9px 30px;
      // border: 0;
      border: 1px solid $field-border;
      background: $white;
      transition: all 0.3s;
      line-height: 1.2;
      border-radius: 5px;
      background: #fbfbfb;


      &:disabled {
        opacity: 0.5;
        background: #fbfbfb;
      }

      //  @include placeholder-color(#a6b1b9);
      &:focus {
        border-color: $primary;
        outline: none;
        box-shadow: none;
        background: white;
      }

      &:-webkit-autofill {
        -webkit-box-shadow: 0 0 0px 1000px white inset;
      }
    }

    .validation-message {
      color: $clr-error;
      font-size: 12px;
    }

    .field__error {

      color: $clr-error;
      font-size: 12px;
      margin-top: 3px;

      font-weight: 300;

      p {
        padding: 5px 0;
      }
    }

    .actions {
      font-size: 13px;

      .form-link {
        float: right;
        padding: 10px 0 0 0;
      }

      .btn {
        float: left;
      }
    }
  }
}


.login--actions {
  text-align: right;
}


.alert-message {
  color: $clr-error;
  margin-top: 10px;
  background: #F4F4F4;
  border: 1px solid #E6E6E6;
  display: inline-block;

  p {
    padding: 5px;
    font-size: 13px !important;
  }

  ul {
    list-style: none;
  }

  li {
    padding: 5px;
    font-size: 13px !important;
  }

}

.success-alert-message {
  //color: $clr-error;
  margin-top: 10px;
  color: #155724;
  background-color: #d4edda;
  border-color: #c3e6cb;
  display: inline-block;

  p {
    padding: 5px;
    font-size: 13px !important;
  }

  ul {
    list-style: none;
  }

  li {
    padding: 5px;
    font-size: 13px !important;
  }

}


.login-btn {
  display: block;
  padding: 12px 14px;
  width: 100%;
  border-radius: 5px;
  background: $primary;
  font-size: 16px;
  color: #fff;
  text-align: center;
  line-height: 1.2;
  transition: all 0.3s ease-in-out;
  font-weight: 500;

  &:hover {
    background-color: darken($primary, 10%);
    color: white;
    text-decoration: none;

  }
}





.error--field {
  .form__input {
    border-color: #DE7A7A !important;
  }

  .form__label {
    color: #DE7A7A;
  }

}

.form-grid-row {
  display: flex;
  gap: 10px;
  justify-content: space-between;

  .form__group {
    flex: 1;
  }
}


.two-factor-authentication {
  accent-color: $primary;

  input {
    position: relative;
    top: 2px;
    margin: 0 5px 0 0;
    padding: 0;
  }
}


.forgotpwd {
  text-align: center;
}


// new login page

@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@keyframes blinker {
  from {opacity: 1.0;}
  to {opacity: 0.0;}
}

.svg-bot {
  * {
    // opacity: 0;
    // transition: var(--transition);
    animation-name: fadeIn;
    animation-duration: 4s;
    animation-fill-mode: both;
  }
  .svg-head {
    animation-delay: 0.6s;
  }
  .svg-bot-eyes {
    animation-delay: 0.8s;
  }
  .svg-mic {
    animation-delay: 1s;
  }
  .svg-signal {
    animation-delay: 1.2s;
  }
  .svg-r {
    animation-delay: 1.4s;
  }
  
  .svg-e {
    animation-delay: 1.6s;
  }
  .svg-cx {
    animation-delay: 1.8s;
  }

}


.login-main-wrapper {
  display: flex;
  width: 100%;
  height: 100%;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;

  .content-wrapper {
    height: auto;
    display: block;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 27.345px;

    .logo {
      text-align: center;
    }

    .sign-in-options {
      display: flex;
      align-items: center;
      border-radius: 10px;
      border: 1px solid $nuetral-200;
      background: $shades-00;
      overflow: hidden;
      animation-name: fadeIn;
      animation-delay: 2.4s;
      animation-duration: 1s;
      animation-fill-mode: both;
      transition: var(--transition);

      /* Shadow/xxlarge */
      box-shadow: 0px 25px 50px -12px rgba(16, 24, 40, 0.25);

      .option {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 16px;
        padding: 30px;
        width: 306px;
        transition: var(--transition);

        &:last-child {
          border-left: 1px solid $nuetral-200;
        }


        &:hover {
          background: $primary-50;

          button {
            border-radius: 8px;
            border: 1px solid $primary;
            background: $primary;

            /* Shadow/xs */
            box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
          }
        }

        .icon {
          i {
            font-size: 22px;
            color: $nuetral-400;
          }
        }

        .text {
          color: $nuetral-700;
          text-align: center;
          font-family: Gilroy;
          font-size: 18px;
          font-style: normal;
          font-weight: 600;
          line-height: 32px;
          /* 177.778% */
        }

        .sub-text {
          color: $nuetral-600;
          text-align: center;
          font-family: Gilroy;
          font-size: 12px;
          font-style: normal;
          font-weight: 500;
          line-height: 130%;
          /* 15.6px */
        }

        .btn-with-icon {
          display: flex;
          display: flex;
          align-items: center;
          align-self: stretch;
          padding: 10px 18px;
          justify-content: center;
          gap: 8px;
          flex: 1 0 0;

          border-radius: 8px;
          border: 1px solid $primary-500;
          background: $nuetral-700;

          transition: var(--transition);


          /* Shadow/xs */
          box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);

          i {
            position: relative;
            left: 0;
            transition: var(--transition);
          }

          &:hover, &:focus {
            i {
              left: 4px;
            }
          }

          &:active, &:focus {
            background: darken($primary, 10%);
          }
        }
      }
    }
  }
}

@media (max-width: 1024px) {
  .sign-in-options {
    flex-direction: column;
  }
}