$primaryColor: #4B4EFC !default; 
$fontWeight:500 !default;
$fontFamily:"Gilroy-Medium", sans-serif !default;
$primaryLightColor: scale-color($primaryColor, $lightness: 60%) !default;
$primaryDarkColor: scale-color($primaryColor, $lightness: -10%) !default;
$primaryDarkerColor: scale-color($primaryColor, $lightness: -20%) !default;
$primaryTextColor: #ffffff !default;
$focusShadow:none !default;

$highlightBg: #E3F2FD !default; 
$highlightTextColor: #495057 !default;
$overlayContainerShadow:0px 3px 26px rgba(0, 0, 0, 0.1) !default;
$borderRadius:5px !default;
$buttonPadding:.625rem 1rem !default;
$inputPadding:.625rem .5rem !default;


$dialogFooterPadding:1.5rem 1.5rem 1.5rem 1.5rem !default;

@import '../_variables';
