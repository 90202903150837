.main-card-grid {
  padding: 20px 0 15px;
  display: flex;
  flex-flow: row wrap;
  position: relative;
  flex-basis: auto;

  @include respondTo($sm) {
    padding-top: 10px;
    padding-bottom: 10px;
  }

  h4 {
    color: #999;
    font-size: 11px;
    margin-bottom: 1px;
  }



  .card-grid-item {
    padding: 0 8px 16px 8px;
    flex-basis: 20%;

    @include respondTo($sm) {
      flex-basis: 100%;
    }

  }

  .card {
    padding: 20px;
    position: relative;
    background: white;
    height: 178px;
    width: 100%;
    border-radius: 10px;
    box-shadow: 0px 1px 3px rgba(16, 24, 40, 0.1), 0px 1px 2px -1px rgba(16, 24, 40, 0.1);



    &.card--create {
      background: none;
      text-align: center;
      border: 2px dashed #d5d5d5;

      h3 {
        font-size: 14px;
        width: 100%;

        i {
          opacity: 0.5;
          display: block;
          margin-bottom: 5px;
          font-size: 22px;
        }
      }

      &:hover {
        cursor: pointer;
        border-color: #ababab;
        color: $primary;

        i {
          opacity: 1;
        }
      }
    }

    h2 {
      font-size: 16px;
      font-weight: normal;
      color: #000;
      line-height: 20px;
      margin: 10px 0;
      word-break: break-word;
    }

    .year-info {
      color: #999;
      font-size: 11px;
    }

    &:hover {
      cursor: pointer;
      box-shadow: 0px 10px 15px -3px rgba(16, 24, 40, 0.1), 0px 4px 6px -4px rgba(16, 24, 40, 0.1);

      transform: transform(2px 0 0 0);
      //background: #e1e1fe;
    }

    .card__footer {
      display: flex;
      position: absolute;
      bottom: 20px;
      left: 20px;
      right: 20px;
      justify-content: space-between;
      align-items: center;

      .tooltipstered {
        margin-top: 20px;
        width: 70%;
      }

      .options {
        font-size: 20px;
        color: #33373a;
        cursor: pointer;
      }
    }
  }
}


.created-by {
  margin: 0;

  p {
    color: $nuetral-500;
    text-transform: capitalize;
    font-size: 12px;
    margin-bottom: 5px;

    span {
      color: $primary;
    }
  }
}


.expiry-by {
  margin: 0;

  p {
    color: $nuetral-500;
    text-transform: capitalize;
    font-size: 12px;
    margin-bottom: 5px;

    span {
      color: $destructive-500;
    }
  }
}

.box-footer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: absolute;
  bottom: 15px;
  width: 85%;
}


.arrow-box {
  background: $primary;
  width: 28px;
  height: 28px;
  text-align: center;
  line-height: 28px;
  color: white;
  border-radius: 3px;
}


.status {
  padding: 0 10px 0 20px;
  line-height: 20px;
  border-radius: 50px;
  color: $nuetral-800;
  display: inline-block;
  font-weight: 500;
  background: $nuetral-100;
  font-size: 11px;
  position: relative;

  &:before {
    position: absolute;
    left: 8px;
    top: 6px;
    border-radius: 100%;
    content: '';
    width: 8px;
    height: 8px;
    background: $nuetral-500;
  }

  &.inprogress,
  &.unsubscribed,
  &.survey_viewed,
  &.invitations_sent,
  &.invitations_reminder_sent,
  &.sent,
  &.email_sent {
    background: $warning-100;

    &:before {
      background: $warning-500;
    }
  }

  &.expired,
  &.failed,
  &.survey_expired,
  &.invitations_bounced,
  &.detractor,
  &.survey_alert,
  &.undelivered {
    background: $destructive-100;

    &:before {
      background: $destructive-500;
    }
  }


  &.completed,
  &.survey_completed,
  &.delivered,
  &.survey_opened,
  &.email_delivered,
  &.invitations_delivered,
  &.email_read,
  &.promotor {
    background: $success-100;

    &:before {
      background: $success-500;
    }
  }
}





.top-card {
  display: flex;
  justify-content: space-between;
  align-items: center;

}


.status-no-survey {


  .no-survey {
    color: $nuetral-400;
    font-size: 14px;
    text-align: center;
    height: 18px;
    top: 50%;
    left: 50%;
    position: absolute;
    margin: -45px 0 0 -40px;

    img {
      margin-bottom: 2px;
    }
  }

}

.filter-bar {
  display: flex;
  justify-content: space-between;
  align-items: center;

  .main-heading {
    font-size: 18px;
    margin: 0 10px;

    @include respondTo($sm) {
      margin: 0 0 5px 0;
    }
  }

  @include respondTo($sm) {
    flex-direction: column;
    align-items: start;
    padding: 0 10px;
  }
}