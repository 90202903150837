@import 'vendor/fontawesome/fontawesome';
@import 'vendor/fontawesome/light';
@import 'vendor/fontawesome/solid';
@import 'vendor/fontawesome/regular';
@import 'vendor/fontawesome/duotone';
@import 'vendor/fontawesome/duotone-icons';
@import 'vendor/fontawesome/brands';
@import 'typefaces';

@import 'variables';
@import 'mixins';
@import 'layout';
@import 'chat-bot';
@import 'bot-studio';

// Susy
// @import "vendor/susy";
// @import "vendor/breakpoint";

/** 
 * App Configuration
 */

//@import "app-config";
@import 'mixin-respondTo';
@import 'spacing-helper';
@import 'loader';

// structure
//@import "mixins";

@import 'login';
@import 'reset';
@import 'initials';

/* Helpers */
@import 'helpers';
@import 'sidebar';

@import 'header'; // defines the whole layout
@import 'forms';
@import 'messages';
@import 'survey-cards';
@import 'sidebar';
@import 'loader';

@import 'vendor/prime-react/themes/saga/saga-blue/theme.scss';
@import 'primeng-overwrites';




//@import "animate";

#root {
    height: 100%;
}
