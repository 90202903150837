.p-overlaypanel {
  &.border-radius-5 {
    border-radius: 5px;
  }

  &.no--padding {
    .p-overlaypanel-content {
      padding: 0;
    }
  }
}


.field-checkbox {
  label {
    margin-left: 0.5rem;
    line-height: 1.5;
  }

}

.p-tabmenu .p-tabmenu-nav .p-tabmenuitem .p-menuitem-link:not(.p-disabled):focus {

  box-shadow: none !important;
}


.p-tabview .p-tabview-panels{
  background: none;
 
}

.p-tabview .p-tabview-nav{
  justify-content: center;
}

.p-tabview .p-tabview-nav li .p-tabview-nav-link:not(.p-disabled):focus{
  box-shadow: none;
 // font-weight: 500;
}

.p-tabview .p-tabview-nav li .p-tabview-nav-link{
  $primary-font-medium: "Gilroy-Medium", sans-serif;
}