.parent-container {
    display: flex;
}

/* Create bot container */

.create-bot-container {
    padding: 10px 38px 50px 310px;
}

.create-bot-sidebar {
    left: 60px;
    padding: 0;
    top: 50px;
    position: fixed;
    height: 100%;
    z-index: 6;
    background: #fff;
    width: 280px;
    border-right: 1px solid $nuetral-200;
    padding: 20px;
}

.create-bot {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 5px 5px 0;

    h2 {
        font-size: 11px;
        color: $nuetral-800;
        // font-weight: 600;
        font-family: $primary-font-semibold;
        font-weight: 600;
    }

    i {
        font-size: 14px;
        color: $nuetral-400;
    }
}

.bot-action {
    display: flex;
    justify-content: end;
    gap: 10px;
    padding-bottom: 20px;

    .back-btn {
        margin-right: auto;
    }
}

.source-top {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 15px 0;

    &.source--setting {
        padding-bottom: 0;
    }

    p {
        font-weight: 500;
    }
}

/* Bot source container */
.white-container-box {
    border-radius: 5px;
    border: 1px solid $nuetral-200;
    background: white;
    padding: 40px;

    &.main {
        padding-top: 20px;
        padding-bottom: 20px;
        margin-bottom: 20px;
        overflow-y: auto;
        /* Add this to enable vertical scrolling */
        max-height: 300px;
        /* Adjust the height as needed */
    }
}

.grid-text {
    font-weight: 500;
    color: $primary;
}

.container-bot {
    margin: 40px auto 0;
    padding-right: 10px;
    padding-left: 10px;
    max-width: 650px;
    height: 100%;
    @include clearfix();
}

.sources-list {
    display: flex;
    flex-direction: column;
}

.source-item {
    display: flex;
    align-items: center;
    padding: 10px;
    cursor: pointer;
    margin-bottom: 1px;
    border-radius: 5px;

    &:hover,
    &.active {
        background-color: $primary-100;

        .source-icon {
            color: $primary-600;
        }

        .source-name {
            color: $primary-600;
        }
    }

    .source-icon {
        color: $nuetral-400;
        display: flex;
        text-align: center;
        margin-right: 10px;
        font-size: 18px;
        width: 24px;
        height: 24px;
        flex-direction: column;
        justify-content: center;
    }

    .source-name {
        color: $nuetral-500;
        font-family: Gilroy;
        font-size: 12px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
    }

    .notification-circle {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 17.25px;
        height: 17.25px;
        flex-shrink: 0;
        border-radius: 50%;
        margin-left: auto;
        background-color: $primary;
        color: #fff;

        &.no-source {
            background-color: #ccc;
            color: $nuetral-600;
        }
    }
}

// Website source
.clear-btn {
    display: flex;
    width: 44px;
    height: 36px;
    padding: 10px 12px;
    justify-content: center;
    align-items: center;
    gap: 8px;
    flex-shrink: 0;

    border-radius: 5px;
    border: 1px solid #d0d5dd;
    background: #fff;

    /* Shadow/xs */
    box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
    color: $nuetral-500;
}

// .back-btn {
//     display: flex;
//     height: 32px;
//     padding: 10px 12px;
//     justify-content: center;
//     align-items: center;
//     gap: 8px;

//     border-radius: 5px;
//     border: 1px solid var(--gray-300, #d0d5dd);
//     background: var(--white, #fff);
//     color: $nuetral-500;

//     /* Shadow/xs */
//     box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
// }

// .save-btn {
//     display: flex;
//     height: 32px;
//     padding: 10px 12px;
//     justify-content: center;
//     align-items: center;
//     gap: 8px;
//     border-radius: 5px;
//     background: $primary;
// }

// .add-btn-website {
//     display: flex;
//     width: 100%;
//     height: 36px;
//     padding: 10px 12px;
//     justify-content: center;
//     align-items: center;
//     gap: 8px;
//     flex-shrink: 0;

//     border-radius: 5px;
//     background: #4b4efc;
//     color: $nuetral-50;
// }

// Document

.doc-info-container {
    display: flex;

    align-items: center;
    justify-content: space-between;
    margin-bottom: 10px;
}

.doc-info-label {
    color: $nuetral-400;
    font-size: 12px;
    font-style: normal;
    margin-right: 5px;
    line-height: normal;
}