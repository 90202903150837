
/* Custom Gilroy Font */
@font-face {
    font-family: "Gilroy-Light";
    src: url('../fonts/gilroy/gilroy-light-webfont.woff2');
    src: url('../fonts/gilroy/gilroy-light-webfont.woff');
}

@font-face {
    font-family: "Gilroy-Regular";
    src: url('../fonts/gilroy/gilroy-medium-webfont.woff2');
    src: url('../fonts/gilroy/gilroy-medium-webfont.woff');
}

@font-face {
    font-family: "Gilroy-Medium";
    src: url('../fonts/gilroy/gilroy-medium-webfont.woff2');
    src: url('../fonts/gilroy/gilroy-medium-webfont.woff');
}

@font-face {
    font-family: "Gilroy-Semibold";
    src: url('../fonts/gilroy/gilroy-semibold-webfont.woff2');
    src: url('../fonts/gilroy/gilroy-semibold-webfont.woff');
}

@font-face {
    font-family: "Gilroy-Bold";
    src: url('../fonts/gilroy/gilroy-bold-webfont.woff2');
    src: url('../fonts/gilroy/gilroy-bold-webfont.woff');
}


/* Custom Axiforma Font */

// @font-face {
//     font-family: "Axiforma-Regular";
//     src: url('./fonts/axiforma/Axiforma-Regular.woff');
// }