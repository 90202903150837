.white-button {
    background: white;
}

.page-header {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-content: center;
    align-items: center;

    h1 {
        font-size: 18px;
        font-weight: 500;
    }
}

// .resend-link {
//     color: $primary;
//     @include transition();
//     padding-top: 3px;
//     text-decoration: underline;
//     display: block;
// }

.content {
    float: left;
    width: 100%;
    // padding: 55px 0 0 50px;
}

.apps-list {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    padding-bottom: 20px;

    .list__item {
        text-align: left;
        //flex: 1;
        flex: 1 0 20%;
        margin: 8px;
    }
}

.container {
    // max-width: 1300px;
    &.form-container {
        max-width: 800px;
        margin: 20px auto;
    }
}

.grid-container {
    background: white;
    border-radius: 10px;
    box-shadow: 0px 1px 3px rgba(16, 24, 40, 0.1), 0px 1px 2px -1px rgba(16, 24, 40, 0.1);
}

.app-content {
    float: left;
    width: 100%;
    background: white;
    border-radius: 10px;
    box-shadow: 0px 1px 3px rgba(16, 24, 40, 0.1), 0px 1px 2px -1px rgba(16, 24, 40, 0.1);
    padding: 20px;

    &.nopadding {
        padding: 0;
    }

    .content__inner {
        padding: 20px;
    }

    h2 {
        float: left;
        font-size: 18px;
        padding: 0 0 15px;
        font-weight: 400;
        margin: 0;
    }

    .search-result {
        border: 1px solid #e9e9e9;
        padding: 20px;
        border-radius: 3px;
        background: #f5f5f5;

        li {
            list-style-type: none;
            display: revert;
            color: #676a6e;
            font-size: 12px;
            margin-bottom: 5px;

            .btn {
                background: #fff;
                color: #313a46;
                padding: 8px 15px;
                font-weight: 500;
                border-radius: 20px;
                cursor: pointer;
                border: 1px solid $primary;

                &:hover,
                &:active {
                    color: $white;
                    background-color: $primary;
                }
            }
        }
    }
}

.grey-container-box {
    float: left;
    width: 100%;
    padding: 15px;
    margin-bottom: 10px;
    position: relative;
    position: relative;
    background: $nuetral-100;
    border: 1px solid $nuetral-200;
    border-radius: 5px 5px 5px 0px;

    label {
        font-weight: 400;
        color: $nuetral-700;
    }
}

.grey-container-box-bot {
    // background-color: $primary-100;
    background: white;
    padding: 10px 15px 10px 15px;
    height: fit-content;
    width: fit-content;
    margin-top: 10px;
    margin-left: 0px;
    margin-bottom: 10px;
    border: 1px solid #e5e7eb;
    box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.1);
    align-items: center;
    border-bottom-left-radius: 10px;
    border-top-right-radius: 10px;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    max-width: 80%;
}

.grey-container-box-error {
    background: #fce1e1;
    padding: 10px 30px 40px 15px;
    margin-bottom: 10px;
    color: #c73131;
    position: relative;
    border-radius: 10px;
    height: 40px;
}

.vertical-separator {
    height: 80vh;
    background-color: #f9f8f9;
    border: 1px solid #d0d7dd;
    margin: 2em 0;
}

.chat {
    float: left;
    width: 100%;
    border: 1px solid #e9e9e9;
    padding: 15px 20px;
    background: #f5f5f5;
    border-radius: 5px;

    li {
        margin-bottom: 10px;
        position: relative;

        p {
            display: inline-block;
            background: white;
            padding: 7px 20px;
            font-size: 12px;
            border-radius: 25px;
        }

        .btn--undo {
            position: absolute;
            border-radius: 100%;
            width: 25px;
            height: 25px;
            line-height: 34px;
            padding: 0;
            // border: 2px solid #f9f8f9;
            //left: -30px;
            //left: 0;
            top: 50%;
            margin-top: -17px;
            font-size: 14px;
            text-align: center;

            &:hover {
                color: red;
            }
        }

        .btn--skip {
            position: absolute;
            border-radius: 100%;
            width: 25px;
            height: 25px;
            line-height: 34px;
            padding: 0;
            font-size: 14px;
            text-align: center;
            // border: 2px solid #a5a6a9;
            //  right: -25px;
            top: 50%;
            margin-top: -17px;

            &:hover {
                color: red;
            }
        }

        &.user {
            text-align: right;

            p {
                background: $primary;
                color: white;
            }
        }
    }
}

// .top-dropdown{
//   float:right;
// }

.field-dropdown {
    display: flex;
    justify-content: space-between;
    gap: 10px;

    .dropdown__container {
        flex: 1;
    }
}

.chat-window-static {
    .rcw-widget-container {
        position: static;
        height: 65vh;
    }
}

.flex {
    display: flex;
}

.space-between {
    justify-content: space-between;
}

.status {
    padding: 0 10px 0 20px;
    line-height: 20px;
    border-radius: 50px;
    color: $nuetral-800;
    display: inline-block;
    font-weight: 500;
    background: $nuetral-100;
    font-size: 12px;
    position: relative;

    &:before {
        position: absolute;
        left: 8px;
        top: 6px;
        border-radius: 100%;
        content: '';
        width: 8px;
        height: 8px;
        background: $nuetral-500;
    }

    &.created {
        background: $primary-100;

        &:before {
            background: $primary-500;
        }
    }

    &.inprogress,
    &.unsubscribed,
    &.sent,
    &.training,
    &.email_sent {
        background: $warning-100;

        &:before {
            background: $warning-500;
        }
    }

    &.expired,
    &.failed,
    &.error,
    &.pending,
    &.detractor,
    &.undelivered {
        background: $destructive-100;

        &:before {
            background: $destructive-500;
        }
    }

    &.completed,
    &.delivered,
    &.survey_opened,
    &.email_delivered,
    &.email_read,
    &.trained,
    &.promotor {
        background: $success-100;

        &:before {
            background: $success-500;
        }
    }
}

.filters {
    display: flex;
    justify-content: flex-end;
    align-items: center;

    .item--action {
        .action__button {
            margin-right: 5px;
            display: inline-block;

            &:last-child {
                margin: 0;
            }
        }
    }

    &.flex-start {
        justify-content: flex-start;
    }

    .filter__item {
        margin-left: 10px;
    }
}

.tooltip-info-container {
    background-color: #e0e0e0;
    padding: 10px;
    width: 400px;
    max-height: 200px;
    overflow-y: auto;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.expanded-row-container {
    background-color: $white;
    box-shadow: 0px 1px 3px rgba(16, 24, 40, 0.1), 0px 1px 2px -1px rgba(16, 24, 40, 0.1);
    border-radius: 10px;
    padding: 10px 0;
    max-height: 200px;
    overflow-y: auto;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.resource-row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 10px 15px;
    border-bottom: 1px solid $nuetral-100;

    &:last-child {
        border: 0;
    }

    .resource-label {
        color: $nuetral-900;

        .resource-icon {
            font-family: 'Font Awesome 6 Pro';
            font-style: normal;
            font-weight: 300;
            font-size: 16px;
            line-height: 1;
            color: $nuetral-400;
            margin-right: 10px;
        }
    }
}

.progress-text {
    color: #66686b;
    font-weight: 500;
    text-align: center;
    align-items: center;
    margin-left: 10px;
}

.error-label {
    // margin-top: 5px;
    color: $destructive-500;
    margin-top: 3px;

    // p {
    //     color: $destructive-500;
    //     margin-top: 3px;
    // }
}

.error-chat-message {
    color: $destructive-700;
    margin-top: 5px;

    p {
        color: $destructive-700;
        margin-top: 3px;
    }
}

.dialog-heading {
    font-weight: 500;
    margin-bottom: 10px;
}

.agent-message {
    background: white;
    padding: 10px 15px 10px 15px;
    height: fit-content;
    width: fit-content;
    margin-top: 10px;
    margin-left: 10px;
    margin-bottom: 10px;
    border: 1px solid #e5e7eb;
    box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.1);
    display: flex;
    align-items: center;
    border-bottom-left-radius: 10px;
    border-top-right-radius: 10px;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    max-width: 80%;
}

.icon-buttons-row {
    display: flex;
    flex-direction: row;
    list-style: none;
    padding: 0;
    margin: 0;
}

.icon-buttons-row {
    display: flex;
    flex-direction: row;
    list-style: none;
    padding: 0;
    margin: 0;
}

.user-name {
    font-weight: 600;
}

.user-initial {
    width: 28px;
    width: 28px;
    text-align: center;
    line-height: 28px;
    border-radius: 100%;
    color: $nuetral-800;
    display: inline-block;
    font-weight: 500;
    background: $primary-100;
    color: $primary-500;
    font-size: 12px;
    position: relative;
    margin-right: 10px;

    &.user--0 {
        background: $success-100;
        color: $success-500;
    }

    &.user--1 {
        background: $destructive-100;
        color: $destructive-500;
    }

    &.user--2 {
        background: $warning-100;
        color: $warning-500;
    }

    &.user--3 {
        background: $nuetral-100;
        color: $nuetral-500;
    }

    &.user--4 {
        background: $primary-100;
        color: $primary-500;
    }

    &.user--5 {
        background: $success-100;
        color: $success-500;
    }
}

////
.user-name {
    font-weight: 600;
}

.user-initial {
    width: 28px;
    width: 28px;
    text-align: center;
    line-height: 28px;
    border-radius: 100%;
    color: $nuetral-800;
    display: inline-block;
    font-weight: 500;
    background: $primary-100;
    color: $primary-500;
    font-size: 12px;
    position: relative;
    margin-right: 10px;

    &.user--0 {
        background: $success-100;
        color: $success-500;
    }

    &.user--1 {
        background: $destructive-100;
        color: $destructive-500;
    }

    &.user--2 {
        background: $warning-100;
        color: $warning-500;
    }

    &.user--3 {
        background: $nuetral-100;
        color: $nuetral-500;
    }

    &.user--4 {
        background: $primary-100;
        color: $primary-500;
    }

    &.user--5 {
        background: $success-100;
        color: $success-500;
    }
}

.knowledge-grey-container-box {
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin: 12px 0px 0px 0px;
    padding: 12px 14px;
    gap: 8px;
    width: auto;
    height: auto;
    background: #ffffff;
    border: 1px solid #e5e7eb;
    border-radius: 8px 8px 8px 8px;
    flex: none;
    order: 1;
    flex-grow: 0;

    .h2 {
        font-family: 'Gilroy';
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        line-height: 20px;
        color: #000000;
        flex: none;
        order: 1;
        flex-grow: 0;
    }

    .source-icon {
        margin-right: 5px;
    }
}

.source-tag {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 6px;
    isolation: isolate;
    width: auto;
    height: 20px;
    background: #e1e1fe;
    border-radius: 50px;
    flex: none;
    order: 1;
    flex-grow: 0;
    padding: 5px;
    font-weight: 600;
}

.source {
    margin: 12px 0px 0px 0px;
    padding: 12px 14px;
    gap: 8px;
    border: 1px solid $nuetral-200;
    border-radius: 10px;

    .source-content {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin: 7px 0;

        i {
            font-size: 14px;
            color: $nuetral-400;
            margin-right: 5px;
        }
    }
}

.Chat-detail-header {
    width: 111px;
    height: 11px;
    font-family: 'Gilroy';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 75.8%;
    margin: 12px 0px 12px 0px;
    text-transform: capitalize;
    color: #000000;
    flex: none;
    order: 0;
    flex-grow: 0;
}

.agent-text {
    width: auto;

    height: auto;
    font-family: 'Gilroy';
    font-style: normal;

    font-weight: 500;

    font-size: 12px;
    line-height: 20px;
    color: $nuetral-800;
    display: flex;
    order: 1;
    flex-grow: 0;
}

.agent-facing-bot-separator {
    position: absolute;
    width: 810px;
    height: 0px;
    left: 904px;
    top: 1px;
    border: 1px solid #e5e7eb;
    transform: rotate(90deg);
}

.custom-file-upload {
    border: 1px solid #ccc;
    display: inline-block;
    padding: 6px 12px;
    cursor: pointer;
    border-radius: 4px;
    background-color: #f1f1f1;
    color: #555;
    font-size: 14px;
    text-align: center;
}

.custom-file-upload:hover {
    background-color: #ddd;
}

.custom-file-upload i {
    margin-right: 5px;
}

input[type='file'] {
    display: none;
}

.converse-sidebar {
    display: flex;
    justify-content: space-between;
    height: 100vh;

    .col-left {
        width: 50%;
        height: 100vh;
        padding: 20px;
    }

    .col-right {
        width: 50%;
        background: $nuetral-50;
        border-left: 1px solid $nuetral-300;
        height: 100vh;
        padding: 20px;
    }
}

.converse--sidebar {
    .p-sidebar-content {
        padding: 0;
    }

    .p-sidebar-header {
        display: none;
    }
}

.bot-drawer-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    align-content: center;
    margin-bottom: 10px;

    h2 {
        font-size: 14px;
        font-weight: 600;
    }
}

.converse-close {
    width: 24px;
    height: 24px;
    line-height: 24px;
    color: #313a46;
    border: 0;
}

.toggle-list {
    li {
        display: flex;
        margin: 0 10px 0 0;

        .toggle {
            padding-right: 5px;
            padding-left: 0;
            margin-left: 0;
        }
    }
}

.time-specification {
    // margin: 20px 0px 0px 0px;
    padding: 5px 0;
    gap: 8px;
    // border: 1px solid $nuetral-200;
    // border-radius: 10px;

    .time-content {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin: 5px 0;

        i {
            font-size: 14px;
            color: $nuetral-400;
            margin-right: 5px;
        }
    }
}

.selected-bot {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    justify-content: space-between;
}

.accordion-icon {
    color: $primary;
    font-size: 16px;
}

.p-tabview .p-tabview-nav {
    background: none;
}

.p-tabview .p-tabview-nav li .p-tabview-nav-link {
    font-size: 13px;
    font-weight: 500;
}

.p-tabview .p-tabview-nav li .p-tabview-nav-link,
.p-tabview .p-tabview-nav li.p-highlight .p-tabview-nav-link {
    background: none;
}

.p-tabview .p-tabview-nav li .p-tabview-nav-link,
.p-tabview .p-tabview-nav li.p-highlight .p-tabview-nav-link {
    outline: none;
}

.custom-nobg {
    .p-tabview-panels {
        background: none;
    }
}

.flex-item-center {
    display: flex;
    justify-content: space-between;
    align-items: center;

    i {
        font-size: 14px;
        color: $nuetral-400;
        margin-right: 5px;
    }

    p {
        font-size: 12px;
        color: $nuetral-800;
    }
}

.bot-action-icon {
    ul {
        display: flex;
        gap: 10px;

        i {
            font-size: 16px;
            color: $nuetral-400;
        }
    }

    &.disabled {
        opacity: 0.5;
        cursor: not-allowed;
    }
}

.knowledge-icon {
    font-size: 18px;
    color: $nuetral-400;
    margin-right: 8px;
}

.sort-icon-table {
    position: relative;
    width: 18px;
    height: 20px;
    display: block;
    font-style: initial;

    &:before {
        position: absolute;
        top: 8px;
        content: '\f107';
        font-size: 12px;
        color: $nuetral-500;
        font-family: 'Font Awesome 6 Pro';
        left: 50%;
        margin-left: -5px;
    }

    &:after {
        position: absolute;
        content: '\f106';
        font-size: 12px;
        color: $nuetral-300;
        font-family: 'Font Awesome 6 Pro';
        left: 50%;
        margin-left: -5px;
    }
}

.bot-source-containerr {
    position: relative;
    // width: 1251px;
    height: auto;
    // padding: 20px;
    // left: 121px;
    top: 10px;
    background: #ffffff;
    box-shadow: 0px 1px 3px rgba(16, 24, 40, 0.1), 0px 1px 2px -1px rgba(16, 24, 40, 0.1);
    border-radius: 10px;
}

.tooltip-content {
    width: 600px;
}

.tooltip-title {
    margin-left: 10px;
    margin-bottom: 10px;
}

.tooltip-list {
    list-style: none;
    padding: 0;
}

.tooltip-item {
    list-style: none;
    margin-bottom: 10px;
}

.tooltip-bullet {
    color: white;
    margin-right: 5px;
}

.expand-button {
    margin-top: 19px;

    button {
        margin-right: 5px;
    }
}

.chat-control-sidebar {
    position: fixed;
    top: 50px;
    left: 60px;
    width: 280px;
    height: 100%;
    background-color: white;
    border-right: 1px solid $nuetral-200;
    padding: 14px;
}

.chat-control-rightsidebar {
    position: fixed;
    top: 50px;
    right: 0;
    width: 280px;
    height: 100%;
    background-color: white;
    border-left: 1px solid $nuetral-200;
    padding: 14px;
}

.sessions {
    padding: 0;
}

.chat-control-widget {
    //width: 100%;
    padding: 0 0 0 280px;
    //   position: relative;

    .rcw-widget-container {
        position: static;
        height: 92vh;
    }

    .rcw-conversation-container {
        background: none;
        border: 0;
    }

    .rcw-sender {
        background: white;
        box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.1);
    }
}

.source-container-bot {
    background: white;
    border-top-right-radius: 10px;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    padding: 10px 15px 10px 15px;
    box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.1);
    border: 1px solid #e5e7eb;
    margin: 10px 0;
}

.sidebar-actions-top {
    display: flex;
    align-items: center;

    button {
        text-align: center;
        display: inline;
        padding-top: 0.75rem;
        padding-bottom: 0.75rem;

        i {
            margin-right: 5px;
            font-size: 14px;
        }
    }
}

.chat-control-search {
    padding: 10px 0;

    > span {
        display: block;
    }
}

.session-list {
    margin-top: 10px;

    // .disable {
    //     opacity: 0.5;
    //     cursor: not-allowed;
    // }

    .converstion {
        padding: 12px;
        white-space: nowrap;
        position: relative;
        border-radius: 6px;
        padding-left: 25px;

        > i {
            font-size: 14px;
            position: absolute;
            left: 10px;
            top: 15px;
        }

        .p-button {
            opacity: 0;
            visibility: hidden;
            transition: 250ms ease-in-out;
            position: absolute;
            right: 10px;
            top: 5px;
        }

        .edit-button {
            opacity: 0;
            visibility: hidden;
            transition: 250ms ease-in-out;
            position: absolute;
            right: 30px;
            top: 5px;
        }

        p {
            color: $nuetral-500;
            font-weight: 600;
            margin-left: 10px;

            &.bot-name {
                font-weight: normal;
            }
        }

        &.active {
            background: $nuetral-200;
        }

        &:hover {
            .p-button {
                opacity: 1;
                visibility: visible;
            }
        }
    }

    .selected {
        background-color: #ccc;
    }
}

.p-dialog .p-dialog-footer {
    background: #fbfbfb;
    border-top: 1px solid #f2f2f2;
}

.footer-left-sidebar {
    position: absolute;
    padding: 10px;
    bottom: 50px;
    width: 100%;
    left: 0;

    button {
        padding-top: 0.75rem;
        padding-bottom: 0.75rem;
    }
}

.selected-prompt {
    background-color: $primary;
}

.p-button-label {
    font-weight: 500;
}

.source-container-bot {
    ol,
    ul {
        list-style-type: decimal;
        list-style-position: inside;

        p {
            display: inline-block;
        }

        li {
            display: list-item;
            line-height: 20px;
            margin: 2px 0;

            p {
                display: inline;
            }
        }

        ul {
            list-style-type: circle;
            list-style-position: inside;
            padding-left: 15px;
        }
    }
}

.rcw-conversation-container {
    /* width */
    ::-webkit-scrollbar {
        width: 5px;
    }

    /* Track */
    ::-webkit-scrollbar-track {
        background: #e1e2e5;
        border-radius: 10px;
    }

    /* Handle */
    ::-webkit-scrollbar-thumb {
        background: #bdbebf;
        border-radius: 10px;
    }

    /* Handle on hover */
    ::-webkit-scrollbar-thumb:hover {
        background: #b6b6b7;
    }
}

.custom-css-scroll {
    overflow-y: scroll;
    overflow-x: hidden;
    height: 100%;
    max-height: none;
    margin-top: 10px;

    /* width */
    ::-webkit-scrollbar {
        width: 5px;
    }

    /* Track */
    ::-webkit-scrollbar-track {
        background: #e1e2e5;
        border-radius: 10px;
    }

    /* Handle */
    ::-webkit-scrollbar-thumb {
        background: #bdbebf;
        border-radius: 10px;
    }

    /* Handle on hover */
    ::-webkit-scrollbar-thumb:hover {
        background: #b6b6b7;
    }
}

.conversion-state {
    text-align: center;
    position: absolute;
    left: 50%;
    //width: 100%;
    top: 50%;
    margin: -83px 0 0 -100px;

    img {
        margin-bottom: 20px;
    }

    p {
        color: $nuetral-400;
        font-size: 14px;
    }
}

.color-palette {
    background: white;
    padding: 5px;
    cursor: pointer;
    height: 32px;
    border-radius: 3px;
    display: flex;
    max-width: 90px;
    justify-content: flex-start;
    align-items: center;
    gap: 5px;
    border: 1px solid $nuetral-200;

    &.disabled {
        opacity: 0.5;
        cursor: not-allowed;
    }

    .colors__container {
        width: 20px;
        border: 1px solid #d0d7dd;
        height: 20px;
        border-radius: 100%;
    }
}

.theme-dropdown {
    position: absolute;
    z-index: 5;
    left: 0;
    top: 36px;
}

.slider {
    margin: 5px 0;
    padding: 0 3px;
}

.slider-legends {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 8px;
}

.file-upload {
    display: flex;
    justify-content: start;
    align-items: center;
    gap: 10px;

    .p-image {
        border-radius: 100%;
        overflow: hidden;
        width: 32px;
        height: 32px;
        background: #f6f6f6;
        text-align: center;
    }
}

.character-count {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 5px;
    margin-top: 5px;
    font-size: 10px;
    color: $nuetral-400;
}

.logo-image-upload {
    position: relative;

    img {
        width: 32px;
        height: 32px;
    }

    .file-remove {
        position: absolute;
        top: 3px;
        z-index: 100;
        right: -5px;
        background: white;
        border-radius: 100%;
    }
}

.slider-labels {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .slider-value {
        border-radius: 10px;
        color: white;
        min-width: 20px;
        background: $primary;
        min-width: 30px;
        padding: 2px 5px;
        text-align: center;
    }
}

.p-slider-handle {
    width: 20px !important;
    height: 20px !important;
    margin-top: -10px !important;
}
