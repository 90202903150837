//NoDataFound
.nodata {
    text-align: center;
    width: 100%;
    height: 100px;
    padding: 30px 20px;
    .nodata__icon {
        display: inline-block;
        margin-bottom: 5px;
        &:before {
            font-size: 25px;
            vertical-align: middle;
            color: $primary;  
        }
    }
    .nodata__msg {
        font-size: 12px;
    }
}