/* Initials */
* {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
}

html {
    height: 100%;
    font-size: 12px; //Body font size is 14px
}

a {
    text-decoration: none;
    color: $primary;
}

ul {
    list-style: none;
}

// ::placeholder {
//     color: $light-grayish-blue;
//     font-family: $primary-font-regular;
// }

input,
select {
    // font-family: $primary-font-regular;
}

textarea {
    resize: none;
}

button {
    background: transparent;
}

:root {
    // font-size: 62.5%;

    body {
        font-family: $primary-font-medium;
        font-weight: 500;
        //   font-size: 1.6rem; //Body font size is 16px
        /* background-color: $lighter-gray--secondary; */
        background-color: #f3f4f6;
        position: relative;
        height: 100%;

        // &.chat-layout {
        //     overflow: hidden;
        // }
    }
}

main {
    width: 100%;
    height: 100%;

}

.main-content {
    padding: 70px 25px 50px 70px;

    @include respondTo($sm) {
        padding: 60px 10px 50px 70px;
    }
}

.main-heading {
    font-size: 18px;
    margin: 0 10px;
}

.p-tabview-nav-container {
    padding-top: 50px;
}


/* Content Wrapper */

.content-wrapper {
    height: 100%;
    /* display: flex; */
}


.container {
    padding: 50px 0 0 60px;
}

.main {
    padding: 10px;
}




#root,
body,
html {
    height: 100%;
}

.widget-container {
    //  border: 1px solid #E5E7EB;
    background: white;
    border-radius: 10px;
    box-shadow: 0px 1px 3px rgba(16, 24, 40, 0.1), 0px 1px 2px -1px rgba(16, 24, 40, 0.1);
}



.opt-field {
    display: flex;
    gap: 10px;
    align-items: center;
}