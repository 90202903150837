// sidebar MINI

.sidebar {
  position: fixed;
  top: 50px;
  left: 0;
  height: 100%;
  width: 250px;
  background: #fff;
  z-index: 99;
  transition: all 0.5s ease;
  border-right: 1px solid #E5E7EB;
  padding-top: 10px;

  .nav-container {
    height: 100%;
    padding: 0 10px 10px;
    overflow: auto;

    &::-webkit-scrollbar {
      display: none;
    }

    li {
      position: relative;
      list-style: none;
      transition: all 0.5s ease;
      white-space: nowrap;

      .nav-heading {
        font-family: "Gilroy-Medium";
        color: $nuetral-800;
        text-transform: uppercase;
        font-size: 11px;
        font-style: normal;
        transition: all 0.3s ease;
        padding: 10px;
        white-space: nowrap;
        display: block;
        letter-spacing: .5px;
        //font-weight: inherit;
      }

      a {
        display: flex;
        align-items: center;
        text-decoration: none;
        border-radius: 5px;
        cursor: pointer;
        position: relative;

        &:before {
          content: '';
          position: absolute;
          width: 3px;
          left: -10px;
          top: 0;
          opacity: 0;
          height: 100%;
          background: $primary;
        }

        i {
          height: 35px;
          min-width: 35px;
          text-align: center;
          line-height: 35px;
          font-size: 16px;
          cursor: pointer;
          color: #9CA3AF;
          transition: all 0.3s ease;
        }

        .link_name {
          font-size: 12px;
          font-weight: 500;
          transition: all 0.5s ease;
          color: #374151;
          text-transform: capitalize;
        }

        &:hover {
          //  background:#F3F4F6;
        }

        &:hover {
          .link_name {
            color: $primary;
          }

          i {
            color: $primary;
            //font-weight: 600;
          }

        }
      }

      &.active {
        a {
          color: $primary;
          background: $primary-100;

          i {
            color: $primary;
            font-weight: 600;
          }

          &:before {
            display: block;
            opacity: 1;
          }
        }

      }


    }
  }
}

.sidebar.close {
  width: 60px;

  .nav-container {
    overflow: visible;

    .nav-heading {
      opacity: 0;
      pointer-events: none;
    }

    li {
      a {
        .link_name {
          opacity: 0;
          pointer-events: none;
        }
      }
    }
  }
}


.sidebar-overlay {
  position: fixed;
  width: 100%;
  height: 100%;
  // opacity: 0;
  top: 50px;
  left: 0;
  background: rgba(64, 64, 64, 0);
  z-index: 0;
  // visibility: hidden;
  transition: .5s ease-in-out;

  &.active {
    z-index: 20;
    //   opacity: 1;
    //  visibility: visible;
    background: rgba(64, 64, 64, 0.2);
  }
}

.sidebar-tabs {

  // border-bottom: 2px solid #dee2e6;
  // padding: 0 10px;
  a {
    flex-direction: column;

  }

  .p-tabmenuitem {
    width: 33.333%;
  }

  .p-menuitem-icon {
    font-size: 16px;
    margin: 0 0 5px 0 !important;
  }

  .p-menuitem-text {
    font-weight: normal;
  }
}


.ScrollbarsCustom-Track {
  width: 6px !important;
}

.ScrollbarsCustom-Thumb {
  background: $nuetral-400 !important;
}